import { render, staticRenderFns } from "./ReportDetails.vue?vue&type=template&id=1e48636e&scoped=true&"
import script from "./ReportDetails.vue?vue&type=script&lang=js&"
export * from "./ReportDetails.vue?vue&type=script&lang=js&"
import style0 from "./ReportDetails.vue?vue&type=style&index=0&id=1e48636e&lang=scss&scoped=true&"
import style1 from "./ReportDetails.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e48636e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VCard,VIcon,VSheet})
